#home {
  position: relative;
  background-color: #F52549;

  // disable the default padding styles
  .app__wrapper {
    margin-bottom: 0;
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  padding: 6rem 0rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
  h1{
    color: white;
    font-size: 55px;
    @media screen and (min-width: 900px) {
      font-size: 90px;
    }
  }
  h3{
    color: white;
    font-size: 35px;
    @media screen and (min-width: 900px) {
      font-size: 60px;
  }
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .badge-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}