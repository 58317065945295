.app__members {
  flex: 1;
  width: 100%;
  flex-direction: column;
}


.app__members-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  div {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background-color: #fef4f5;

    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}
.app__member-portfolio {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.app__member-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__member-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}